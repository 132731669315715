import React, { useContext } from "react";
import ApplicationContext from "../../applicationContext/ApplicationContext";
import { IHomeProps } from "./IHomeProps";
import "./Style.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export const Home: React.FC<IHomeProps> = (props: IHomeProps): JSX.Element => {
  const context = useContext(ApplicationContext);
  const noOfQs = context.Configuration.getNoOfQsToAsk();
  const questions = context.Configuration.getSelectedNumbers();

  const listItems = questions
    ? questions.map((number) => <div id={number.toString()}>{number},</div>)
    : [];

  function navMenu() {
    return (
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  const home = (): JSX.Element => {
    return (
      <div>
        <div className="center">no of questions to ask:</div>
        <div className="center">{noOfQs}</div>
        <div className="center">times table chosen :</div>
        <div className="center">{listItems}</div>
      </div>
    );
  };

  return <div>{home()}</div>;
};
