import { IConfiguration } from "./IConfiguration";
import { IConfigurationSettings } from "./IConfigurationSettings";

let first: IConfigurationSettings[] = [];
let numbers: number[];
let numberOfQuestionToAsk: string;

export class Configuration implements IConfiguration {
  public addFirstPart(part: IConfigurationSettings): void {
    first.push(part);
  }
  public setSelectedNumbers(selected: number[]): void {
    numbers = [];
    numbers.push(...selected);
  }
  public getSelectedNumbers(): number[] {
    return numbers;
  }
  public setNoOfQsToAsk(numberOfQs: string): void {
    numberOfQuestionToAsk = numberOfQs;
  }
  public getNoOfQsToAsk(): number {
    return Number(numberOfQuestionToAsk) ? Number(numberOfQuestionToAsk) : 0;
  }
}
