import { IQuestion } from "./IQuestion";
import { ITheQuestion } from "./ITheQuestion";

let questions: ITheQuestion[] = [];
let studentQuestions: ITheQuestion[] = [];

export class Questions implements IQuestion {
  public setQuestions(newQs: ITheQuestion[]): void {
    questions = [];
    questions.push(...newQs);
  }
  public getQuestions(): ITheQuestion[] {
    return questions;
  }

  public setStudentQuestion(question: ITheQuestion): void {
    studentQuestions.push(question);
  }
  public getStudentQuestions(): ITheQuestion[] {
    return studentQuestions;
  }

  public clearStudentQuestion(): void {
    studentQuestions = [];
  }
}
