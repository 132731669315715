import React, { useContext, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import ApplicationContext from "../../applicationContext/ApplicationContext";
import { ITheQuestion } from "../../applicationContext/Question/ITheQuestion";
import { IExamProps } from "./IExamProps";
import "bootstrap/dist/css/bootstrap.css";
import correctLogo from "../../images/green_tick.png";
import incorrectLogo from "../../images/incorrect.png";
import "./Style.css";

export const Exam: React.FC<IExamProps> = (props: IExamProps): JSX.Element => {
  const [showStart, setShowStart] = React.useState(true);
  const [showExam, setShowExam] = React.useState(false);
  const [showResult, setShowResult] = React.useState(false);
  const [showQNumber, setShowQNumber] = React.useState(0);
  const [answer, setAnswer] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [qAnswer, setQAnswer] = React.useState(0);
  const [answerIsCorrect, setAnswerIsCorrect] = React.useState(false);

  //timer
  const [time, setTime] = React.useState(0);
  const [timerOn, setTimerOn] = React.useState(false);

  React.useEffect(() => {
    let interval: any = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!timerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerOn]);

  //end timer

  const context = useContext(ApplicationContext);
  const selectedNumbers = context.Configuration.getSelectedNumbers();
  const noOfQs = context.Configuration.getNoOfQsToAsk();

  function setupQuestion() {
    let questions: ITheQuestion[] = [];

    for (var i = 0; i < noOfQs; i++) {
      var noBetween1and12 = Math.floor(Math.random() * 12) + 1;
      var value = Math.floor(Math.random() * selectedNumbers.length);

      const question: ITheQuestion = {
        id: i.toString(),
        first: selectedNumbers[value],
        second: noBetween1and12,
      };
      questions.push(question);
    }

    context.Questions.setQuestions(questions);
  }

  function resetViews() {
    setShowExam(false);
    setShowResult(false);
    setShowStart(false);
  }

  function closeModel() {
    setShowModal(false);
    handleClose();
  }

  function nextQuestion() {
    const questions = context.Questions.getQuestions();
    const question = questions[showQNumber];

    const studentQuestion: ITheQuestion = {
      first: question.first,
      second: question.second,
      id: showQNumber.toString(),
      answer: question.first * question.second,
      entered: parseInt(answer),
    };

    context.Questions.setStudentQuestion(studentQuestion);

    if (
      studentQuestion.entered != null &&
      studentQuestion.answer === studentQuestion.entered
    ) {
      setAnswerIsCorrect(true);
      setShowModal(true);
    } else {
      setAnswerIsCorrect(false);
      setShowModal(true);
      setQAnswer(studentQuestion.answer ? studentQuestion.answer : 0);
    }

    setTimeout(() => closeModel(), 3000);

    console.log(question);
    console.log(context.Questions.getStudentQuestions());
  }

  const enteredAnswer = (event: any) => {
    setAnswer(event.target.value);
  };

  const startQuestions = (event: any) => {
    setTime(0);
    setTimerOn(true);
    const selectedNumbers = context.Configuration.getSelectedNumbers();
    const noOfQs = context.Configuration.getNoOfQsToAsk();

    console.log(selectedNumbers);
    console.log(noOfQs);

    // eslint-disable-next-line use-isnan
    if (selectedNumbers === undefined || noOfQs === Number.NaN) {
      alert("Configuration missing");
      return;
    }

    context.Questions.clearStudentQuestion();

    resetViews();
    setupQuestion();
    setShowExam(true);
  };

  const start = (): JSX.Element => {
    return (
      <div>
        <div className="d-grid gap-2">
          <div className="start">
            <Button
              className="buttonW"
              variant="primary"
              onClick={startQuestions}
              size={"lg"}
            >
              Start
            </Button>
          </div>
        </div>
      </div>
    );
  };

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      nextQuestion();
    }
  }

  const handleClose = () => {
    setShowModal(false);
    setShowQNumber(showQNumber + 1);
    setAnswer("");

    const noOfQs = context.Configuration.getNoOfQsToAsk();

    if (showQNumber + 1 >= noOfQs) {
      resetViews();
      setTimerOn(false);
      setShowResult(true);
    }

    inputReference.current?.focus();
  };

  const Correct = (): JSX.Element => {
    return (
      <div>
        <div>
          <img src={correctLogo} alt="Correct" width={300} height={300} />
        </div>
      </div>
    );
  };

  const incorrect = (): JSX.Element => {
    return (
      <div>
        <div>
          <img src={incorrectLogo} alt="incorrect" width={300} height={300} />
        </div>
      </div>
    );
  };

  const showAnswer = (): JSX.Element => {
    return (
      <>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Results</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>{answerIsCorrect ? Correct() : incorrect()}</div>
              <div>{answerIsCorrect ? <> </> : <> Answer: {qAnswer} </>}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const inputReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputReference.current?.focus();
  }, []);

  const exam = (): JSX.Element => {
    const questions = context.Questions.getQuestions();
    const question = questions[showQNumber];

    const first = question.first;
    const second = question.second;
    return (
      <div>
        <div className="theQuestion">
          {first} x {second} ={" "}
          <input
            type="text"
            value={answer}
            onChange={enteredAnswer}
            autoFocus={true}
            size={1}
            ref={inputReference}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="submit-me">
          <button onClick={nextQuestion}>Submit</button>
        </div>
      </div>
    );
  };

  const results = (): JSX.Element => {
    const noOfQs = context.Configuration.getNoOfQsToAsk();
    const questions = context.Questions.getStudentQuestions();

    const question = questions.map((q) => (
      <div>
        <div>Question no: {q.id + 1}</div>
        <div>
          {q.first} * {q.second} = {q.answer}
        </div>
        <div>your answer: {q.entered ? q.entered : <>no answer given</>}</div>
        <div>{q.answer === q.entered ? <>Correct </> : <>Incorrect</>}</div>
        <div>
          <hr></hr>
        </div>
      </div>
    ));

    let correct = 0;
    questions.map((q) =>
      q.answer === q.entered ? (correct = correct + 1) : 0
    );

    const percentage = (correct / noOfQs) * 100;

    return (
      <div>
        <div>{question}</div>
        <div>
          <hr></hr>
        </div>
        <div>percentage: {percentage}%</div>
        <div id="display">
          <span>Time : </span>
          <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
          <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
          {/* <span>{("0" + ((time / 10) % 100)).slice(-2)}</span> */}
        </div>
      </div>
    );
  };

  return (
    <div>
      {showExam ? exam() : <></>}
      {showStart ? start() : <></>}
      {showResult ? results() : <></>}
      {showAnswer()}
    </div>
  );
};
