import React from "react";
import { Configuration } from "./Configuration/Configuration";
import { IApplicationContext } from "./IApplicationContext";
import { Questions } from "./Question/Question";

export const defaults = {
  Questions: new Questions(),
  Configuration: new Configuration(),
};

export default React.createContext<IApplicationContext>(defaults);
