import ApplicationContext, {
  defaults,
} from "./applicationContext/ApplicationContext";
import { ITimesTableProps, TimesTable } from "./components/timesTable/index";

function App() {
  const timesTableProps: ITimesTableProps = {
    dateTime: new Date(),
  };

  return (
    <ApplicationContext.Provider value={defaults}>
      <TimesTable {...timesTableProps} />
    </ApplicationContext.Provider>
  );
}

export default App;
