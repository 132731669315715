import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import ApplicationContext from "../../applicationContext/ApplicationContext";
import { IConfigurationProps } from "./IConfigurationProps";

export const Configurations: React.FC<IConfigurationProps> = (
  props: IConfigurationProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [times1, setTimes1] = React.useState(false);
  const [times2, setTimes2] = React.useState(false);
  const [times3, setTimes3] = React.useState(false);
  const [times4, setTimes4] = React.useState(false);
  const [times5, setTimes5] = React.useState(false);
  const [times6, setTimes6] = React.useState(false);
  const [times7, setTimes7] = React.useState(false);
  const [times8, setTimes8] = React.useState(false);
  const [times9, setTimes9] = React.useState(false);
  const [times10, setTimes10] = React.useState(false);
  const [times11, setTimes11] = React.useState(false);
  const [times12, setTimes12] = React.useState(false);

  const [noOfQs, setNoOfQs] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  function addConfiguration() {
    let numbersSelected: number[] = [];

    if (times1 === true) numbersSelected.push(1);
    if (times2 === true) numbersSelected.push(2);
    if (times3 === true) numbersSelected.push(3);
    if (times4 === true) numbersSelected.push(4);
    if (times5 === true) numbersSelected.push(5);
    if (times6 === true) numbersSelected.push(6);
    if (times7 === true) numbersSelected.push(7);
    if (times8 === true) numbersSelected.push(8);
    if (times9 === true) numbersSelected.push(9);
    if (times10 === true) numbersSelected.push(10);
    if (times11 === true) numbersSelected.push(11);
    if (times12 === true) numbersSelected.push(12);

    context.Configuration.setSelectedNumbers(numbersSelected);
    context.Configuration.setNoOfQsToAsk(noOfQs);

    console.log(context.Configuration.getSelectedNumbers());
    console.log(context.Configuration.getNoOfQsToAsk());

    setShowModal(true);

    //const test = context.Configuration.getSelectedNumbers();
  }

  const handleClose = () => {
    setShowModal(false);
  };

  const saveModal = (): JSX.Element => {
    return (
      <>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Results</Modal.Title>
          </Modal.Header>
          <Modal.Body>Saved!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const configuration = (): JSX.Element => {
    const handleChange = (value: number) => {
      if (value === 1) setTimes1(!times1);
      if (value === 2) setTimes2(!times2);
      if (value === 3) setTimes3(!times3);
      if (value === 4) setTimes4(!times4);
      if (value === 5) setTimes5(!times5);
      if (value === 6) setTimes6(!times6);
      if (value === 7) setTimes7(!times7);
      if (value === 8) setTimes8(!times8);
      if (value === 9) setTimes9(!times9);
      if (value === 10) setTimes10(!times10);
      if (value === 11) setTimes11(!times11);
      if (value === 12) setTimes12(!times12);
    };

    const numberOfQuestionChanged = (event: any) => {
      setNoOfQs(event.target.value);
    };

    return (
      <div>
        <div>Select which times table you want to test</div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times1}
              onChange={() => handleChange(1)}
            />
            1
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times2}
              onChange={() => handleChange(2)}
            />
            2
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times3}
              onChange={() => handleChange(3)}
            />
            3
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times4}
              onChange={() => handleChange(4)}
            />
            4
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times5}
              onChange={() => handleChange(5)}
            />
            5
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times6}
              onChange={() => handleChange(6)}
            />
            6
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times7}
              onChange={() => handleChange(7)}
            />
            7
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times8}
              onChange={() => handleChange(8)}
            />
            8
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times9}
              onChange={() => handleChange(9)}
            />
            9
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times10}
              onChange={() => handleChange(10)}
            />
            10
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={times11}
              onChange={() => handleChange(11)}
            />
            11
          </label>
          <div>
            <label>
              <input
                type="checkbox"
                checked={times12}
                onChange={() => handleChange(12)}
              />
              12
            </label>
          </div>
        </div>
        <div>
          <hr></hr>
          <div>
            <label>
              Enter number of questions:
              <input
                type="text"
                value={noOfQs}
                onChange={numberOfQuestionChanged}
              />
            </label>
          </div>
        </div>
        <div>
          <hr></hr>
          <button onClick={addConfiguration}>Save</button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {configuration()}
      {saveModal()}
    </div>
  );
};
