import React, { useState } from "react";
import { Configurations, IConfigurationProps } from "../configurations/index";
import { Exam, IExamProps } from "../exam/index";
import { Home, IHomeProps } from "../home/index";
import { ITimesTableProps } from "./ITimesTableProps";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Style.css";

export const TimesTable: React.FC<ITimesTableProps> = (
  props: ITimesTableProps
): JSX.Element => {
  const [homePage, setHomePage] = useState(true);
  const [configurationPage, setConfigurationPage] = useState(false);
  const [examPage, setExamPage] = useState(false);

  const homeProps: IHomeProps = {
    dateTime: new Date(),
  };

  const configurationProps: IConfigurationProps = {
    dateTime: new Date(),
  };

  const examProps: IExamProps = {
    dateTime: new Date(),
  };

  function clearPages() {
    setHomePage(false);
    setConfigurationPage(false);
    setExamPage(false);
  }

  function showHomePage() {
    clearPages();
    setHomePage(true);
  }

  function showConfigurationPage() {
    clearPages();
    setConfigurationPage(true);
  }

  function showExamPage() {
    clearPages();
    setExamPage(true);
  }

  const mainMenu = (): JSX.Element => {
    return (
      <Navbar bg="light" fixed="top" expand="lg">
        <Container>
          <Navbar.Brand>[Multiplications]</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={showHomePage}>Home</Nav.Link>
              <Nav.Link onClick={showConfigurationPage}>Configuration</Nav.Link>
              <Nav.Link onClick={showExamPage}>Exam</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };

  const timesTable = (): JSX.Element => {
    return (
      <div>
        <div className="menuBar">{mainMenu()}</div>
        <div>{homePage ? <Home {...homeProps} /> : <></>}</div>
        <div>
          {configurationPage ? (
            <Configurations {...configurationProps} />
          ) : (
            <></>
          )}
        </div>
        <div>{examPage ? <Exam {...examProps} /> : <></>}</div>
      </div>
    );
  };

  return <div>{timesTable()}</div>;
};
